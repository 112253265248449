import React from 'react';
import PropTypes from 'prop-types';
import FooterWrapper, {
  MenuWrapper,
  CopyrightArea,
  SecondaryFooter,
  EUFlagImage,
} from './Footer.style';
import euFlag from './eu_image.png'; // Import the EU flag image
const Footer = ({ logo, menu, bgSrc, copyright, className, path }) => {
  return (
    <>
      <FooterWrapper id="footer" className={className} bg-img={bgSrc}>
        {logo && logo}
        {menu && <MenuWrapper>{menu}</MenuWrapper>}
        <></>
        <EUFlagImage src={euFlag} alt="EU Flag" />
        {/* Render the sponsored text */}
        <CopyrightArea>
          This project has received funding from the European Union’s{' '}
        </CopyrightArea>
        <CopyrightArea>
          Horizon 2020 research and innovation programme under grant{' '}
        </CopyrightArea>
        <CopyrightArea> agreement No 101004627, Call: H2020-SC6</CopyrightArea>
        <CopyrightArea>
          {' '}
          -TRANSFORMATIONS-2020 – Type of action: IA (Innovation action)
        </CopyrightArea>
      </FooterWrapper>
      {!!path && <SecondaryFooter />}
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.element,
  menu: PropTypes.element,
  bgSrc: PropTypes.string,
  copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
