import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AuthContext } from 'context/AuthProvider';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  PRICING_PLAN_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  ROUTES,
} from 'settings/constant';

const MobileMenu = ({ className }) => {
  // auth context
  const { loggedIn, logOut } = useContext(AuthContext);

  const navigations = [
    {
      label: <NavLink to={ROUTES}>Routes</NavLink>,
      needsLogin: true,
      key: 'hotels',
    },
    {
      label: loggedIn && <button onClick={logOut}>Log Out</button>,
      key: 'logout',
    },
  ];

  return (
    <Menu
      className={className}
      items={navigations.filter((i) => (i.needsLogin ? loggedIn : true))}
    />
  );
};

export default MobileMenu;
