// General Page Section
export const HOME_PAGE = '/';
export const AGENTS_PAGE = '/agents';
export const ROUTES = '/routes';

// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/listing';
export const SINGLE_POST_PAGE = '/post';
export const SINGLE_LOCATION_PAGE = '/location';
export const SINGLE_ROUTE_PAGE = '/route';
export const SINGLE_LEGEND_PAGE = '/legend';
export const SINGLE_EXISTENCE_PAGE = '/existence';
export const SAVE_TOKEN = '/login';
export const ROUTE_CREATOR = '/route-creator';
export const SINGLE_ROUTE = '/r';

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/profile';
export const AGENT_PROFILE_FAVORITE = 'favorite-post';
export const AGENT_PROFILE_CONTACT = 'contact';
export const AGENT_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = 'edit';
export const AGENT_IMAGE_EDIT_PAGE = 'change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = 'change-password';
export const AGENT_PROFILE_DELETE = '/delete';

// Other Pages
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PRIVACY_PAGE = '/privacy';
export const ADD_HOTEL_PAGE = '/add-hotel';

// Login / Registration Page
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';
export const API =
  window.location.hostname === 'localhost'
    ? 'http://localhost:8080'
    : 'https://api.stefan.guide';

export const fetchTokens = async () => {
  const response = await fetch(API + '/tokens', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  });
  return await response.json();
};

export const LOCATIONS_CORDS = [
  {
    id: 1,
    location: {
      type: 'r',
      id: 1,
      lat: 47.316667,
      lng: 28.9666675,
      formattedAddress: 'Cetatea Orhei',
    },
    image: {
      id: 1,
      url: 'https://hailaplimbare.files.wordpress.com/2019/04/cetatile_medievale_ale_moldovei.jpg?w=1024',
      thumb_url:
        'https://hailaplimbare.files.wordpress.com/2019/04/cetatile_medievale_ale_moldovei.jpg?w=1024',
    },
  },
  {
    id: 2,
    location: {
      type: 'r',
      id: 2,
      lat: 48.163359,
      lng: 28.29116,
      formattedAddress: 'Cetatea Soroca',
    },
    image: {
      id: 2,
      url: 'https://www.visit.md/wp-content/uploads/2018/08/soroca-fortrese.jpg',
      thumb_url:
        'https://www.visit.md/wp-content/uploads/2018/08/soroca-fortrese.jpg',
    },
  },
  {
    id: 3,
    location: {
      type: 'r',
      id: 3,
      lat: 46.829582,
      lng: 29.441463,
      formattedAddress: 'Cetatea Tighina',
    },
    image: {
      id: 3,
      url: 'https://casamare.md/components/com_jshopping/files/img_products/full_casamare_cetatea_tighina___5_.jpg',
      thumb_url:
        'https://casamare.md/components/com_jshopping/files/img_products/full_casamare_cetatea_tighina___5_.jpg',
    },
  },
];
