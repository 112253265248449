import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { setStateToUrl, getStateFromUrl } from 'library/helpers/url_handler';
import { LISTING_POSTS_PAGE } from 'settings/constant';
import { NavbarSearchWrapper } from './Header.style';

export default function NavbarSearch() {
  let navigate = useNavigate();
  let location = useLocation();

  return (
    <NavbarSearchWrapper className="navbar_search">
      {/* <MapAutoComplete updateValue={(value) => updateValueFunc(value)} /> */}
      {/* <FiSearch /> */}
    </NavbarSearchWrapper>
  );
}
