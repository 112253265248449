import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'settings/constant';

export const AuthContext = React.createContext();

const fakeUserData = {
  id: 1,
  name: 'Jhon Doe',
  avatar: '/images/profilepic.png',
  roles: ['USER', 'ADMIN'],
};

const AuthProvider = (props) => {
  let navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  const checkSignIn = async (params) => {
    if (localStorage.getItem('token')) {
      setLoggedIn(true);
      const data = localStorage.getItem('selectedTags');
      if (data) {
        const response = await fetch(API + '/questions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
          body: data,
        });
        const jsonResponse = await response.json();
        localStorage.removeItem('selectedTags');
        navigate({
          pathname: '/post/' + jsonResponse.questionId,
        });
      }
    }
  };

  const signIn = (params) => {
    setLoggedIn(true);
    navigate('/', { replace: true });
  };

  const signUp = (params) => {
    setLoggedIn(true);
    navigate('/', { replace: true });
  };

  const logOut = () => {
    localStorage.removeItem('token');
    setUser(null);
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        user,
        checkSignIn,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
