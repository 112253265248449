import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  AGENT_PROFILE_PAGE,
  PRICING_PLAN_PAGE,
  ROUTES,
} from 'settings/constant';
import { AuthContext } from 'context/AuthProvider';

const menuItems = [
  {
    label: <NavLink to={ROUTES}>Routes</NavLink>,
    needsLogin: true,
    key: 'menu-1',
  },
  {
    label: <NavLink to={PRICING_PLAN_PAGE}>Pricing</NavLink>,
    key: 'menu-5',
  },
];

const MainMenu = ({ className }) => {
  const { loggedIn, logOut } = useContext(AuthContext);
  return (
    <Menu
      className={className}
      items={menuItems.filter((i) => (i.needsLogin ? loggedIn : true))}
    />
  );
};

export default MainMenu;
