import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'themes/default.theme';
import GlobalStyles from 'themes/global.style';
import AuthProvider from 'context/AuthProvider';
import AppRoutes from './router';
import 'antd/dist/reset.css';
import { APIProvider } from '@vis.gl/react-google-maps';

const App = () => (
  <APIProvider apiKey={'AIzaSyC34VyjqrtdBHCfveFy4OhEnFYpufHDStQ'}>
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyles />
        <BrowserRouter>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </BrowserRouter>
      </React.Fragment>
    </ThemeProvider>
  </APIProvider>
);
const root = createRoot(document.getElementById('root'));
root.render(<App />);
