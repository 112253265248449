import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { IoIosClose } from 'react-icons/io';
import { Button, Drawer } from 'antd';
import Logo from 'components/UI/Logo/Logo';
import Text from 'components/UI/Text/Text';
import TextLink from 'components/UI/TextLink/TextLink';
import Navbar from 'components/Navbar/Navbar';
import { AuthContext } from 'context/AuthProvider';
import { LayoutContext } from 'context/LayoutProvider';
import useWindowSize from 'library/hooks/useWindowSize';
import { AGENT_PROFILE_PAGE } from 'settings/constant';
import AuthMenu from './AuthMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import NavbarSearch from './NavbarSearch';
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from './Header.style';

const avatarImg = `/images/profilepic.png`;
const LogoIcon = () => (
  <svg
    id="Слой_2_копия2_"
    data-name="Слой 2 (копия2)"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47.98 47.36"
  >
    <path
      class="cls-1"
      d="M7.77,3a70.38,70.38,0,0,1,0,11.34A95.73,95.73,0,0,1,6,25a64.89,64.89,0,0,0-.39,22A2,2,0,0,0,8,48.4a2.05,2.05,0,0,0,1.4-2.46A60.18,60.18,0,0,1,10,25.12,80,80,0,0,0,11.77,3c-.19-2.55-4.2-2.57-4,0Z"
      transform="translate(-1 -1.1)"
      fill="#fff"
    />
    <path
      class="cls-1"
      d="M38.3,3.56a32.18,32.18,0,0,1,1.4,10.27c-.1,3.23-.41,6.46-.48,9.69A79.2,79.2,0,0,0,41.69,45c.64,2.49,4.5,1.43,3.85-1.07a75.36,75.36,0,0,1-2.3-21.15c.09-3.19.42-6.38.47-9.57A35.21,35.21,0,0,0,42.16,2.5C41.42,0,37.56,1.09,38.3,3.56Z"
      transform="translate(-1 -1.1)"
      fill="#fff"
    />
    <path
      class="cls-2"
      d="M18,25.74V12.11l-1.5,1.5H20.3l-1.5-1.5V14a1.52,1.52,0,0,0,1.5,1.5h2.35a1.52,1.52,0,0,0,1.5-1.5V12.11l-1.5,1.5h3.76l-1.5-1.5V14a1.52,1.52,0,0,0,1.5,1.5h2.82a1.52,1.52,0,0,0,1.5-1.5V12.11l-1.5,1.5H33l-1.5-1.5V25.74a1.5,1.5,0,0,0,3,0V12.11a1.52,1.52,0,0,0-1.5-1.5H29.23a1.52,1.52,0,0,0-1.5,1.5V14l1.5-1.5H26.41l1.5,1.5V12.11a1.52,1.52,0,0,0-1.5-1.5H22.65a1.52,1.52,0,0,0-1.5,1.5V14l1.5-1.5H20.3L21.8,14V12.11a1.52,1.52,0,0,0-1.5-1.5H16.54a1.52,1.52,0,0,0-1.5,1.5V25.74a1.5,1.5,0,0,0,3,0Z"
      transform="translate(-1 -1.1)"
      fill="#fff"
    />
    <path
      d="M15,31.53h6.4A1.52,1.52,0,0,0,22.9,30c0-1.19-.31-2.71.38-3.75a2.05,2.05,0,0,1,1.89-1.15,2.81,2.81,0,0,1,1.54,1.13c.72,1,.39,2.61.39,3.77a1.52,1.52,0,0,0,1.5,1.5H35a1.5,1.5,0,0,0,0-3H28.6L30.1,30c0-1.06.08-2.15,0-3.2a5,5,0,0,0-1.24-2.66,5.35,5.35,0,0,0-3.49-2,4.73,4.73,0,0,0-4.19,2,5.5,5.5,0,0,0-1.25,2.52,22.68,22.68,0,0,0,0,3.4l1.5-1.5H15a1.5,1.5,0,0,0,0,3Z"
      transform="translate(-1 -1.1)"
      fill="#fff"
    />
    <path
      class="cls-3"
      d="M4,44.25a15,15,0,0,1,10.59-3,14.6,14.6,0,0,1,5.68,1.87,12.85,12.85,0,0,1,2.74,2,2,2,0,0,0,3.14-.4c1.73-3.35,5.14-6,9.07-5.76a10.71,10.71,0,0,1,5.06,1.92A40.64,40.64,0,0,0,46,44.25a2.07,2.07,0,0,0,2.74-.72A2,2,0,0,0,48,40.79a48.82,48.82,0,0,1-6-3.65,13.71,13.71,0,0,0-7.6-2.25,14,14,0,0,0-11.65,7.75l3.14-.41A18.86,18.86,0,0,0,2,40.79a2.07,2.07,0,0,0-.72,2.74A2,2,0,0,0,4,44.25Z"
      transform="translate(-1 -1.1)"
      fill="#fff"
    />
  </svg>
);

export default function Header() {
  let location = useLocation();
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn } = useContext(AuthContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  const sidebarHandler = () => {
    setState(!state);
  };
  const headerType = location.pathname === '/' ? 'transparent' : 'default';

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === 'transparent' ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <>
                {headerType === 'transparent' && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/logo-alt.svg"
                  title="Stefan.Guide"
                />
              </>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={loggedIn}
            avatar={<Logo src={avatarImg} />}
            profileMenu={<ProfileMenu avatar={<Logo src={avatarImg} />} />}
            headerType={headerType}
            searchComponent={<NavbarSearch />}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <>
                {headerType === 'transparent' && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/logo-alt.svg"
                  title="Stefan.Guide"
                />
              </>
              <NavbarSearch />
            </LogoArea>
            <Button
              className={`hamburg-btn ${state ? 'active' : ''}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              open={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {!loggedIn && <AuthMenu className="auth-menu" />}
              <MobileMenu className="main-menu" />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
}
