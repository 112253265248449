import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'library/hooks/useLocation';
import Sticky from 'react-stickynode';
import Container from 'components/UI/Container/Container';
import Loader from 'components/Loader/Loader';
import useWindowSize from 'library/hooks/useWindowSize';
import Description from './Description/Description';
import Amenities from './Amenities/Amenities';
import Location from './Location/Location';
import Review from './Review/Review';
import Reservation from './Reservation/Reservation';
import BottomReservation from './Reservation/BottomReservation';
import TopBar from './TopBar/TopBar';
import SinglePageWrapper, { PostImage } from './SinglePageView.style';
import PostImageGallery from './ImageGallery/ImageGallery';
import useDataApi from 'library/hooks/useDataApi';
import isEmpty from 'lodash/isEmpty';
import {
  API,
  fetchTokens,
  LISTING_POSTS_PAGE,
  LOCATIONS_CORDS,
} from 'settings/constant';
import { Row, Col, Avatar, Button, List, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  LikeOutlined,
  DislikeOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
const fetchData = async (slug) => {
  const response = await fetch(API + '/routes', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  });
  return await response.json();
};

const deleteQuestion = async (id) => {
  await fetch(API + '/question/' + id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  });
};

const starRoute = async (id, star) => {
  const response = await fetch(API + '/question/' + id + '/star', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify({ star }),
  });
};

const UserRoutes = () => {
  const [initLoading, setInitLoading] = useState(true);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [starred, setStarred] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState(0);
  let navigate = useNavigate();

  useEffect(() => {
    getData();
    fetchTokens().then((res) => {
      setTokens(res.tokens);
    });
  }, []);

  const getData = async () => {
    fetchData().then((jsonResponse) => {
      setInitLoading(false);
      const routes = jsonResponse.routes || [];
      const starred = routes.filter((route) => route.star);
      const unstarred = routes.filter((route) => !route.star);
      setList(unstarred);
      setStarred(starred);
    });
  };

  const deleteQ = (id) => () => {
    setInitLoading(true);
    deleteQuestion(id).then(() => {
      getData();
    });
  };

  const star = (id, star) => () => {
    setInitLoading(true);
    starRoute(id, star).then(() => {
      getData();
    });
  };

  return (
    <SinglePageWrapper>
      <Container>
        <Row gutter={30}>
          <Col md={12} sm={24}>
            <List
              header="Trasee generate"
              footer={
                <div>
                  Tokenuri ramase: {tokens}
                  <br />
                  <br />
                  <Button
                    onClick={() => {
                      navigate({
                        pathname: LISTING_POSTS_PAGE,
                      });
                    }}
                    disabled={tokens <= 0}
                    type="primary"
                  >
                    + Genereaza un traseu
                  </Button>
                </div>
              }
              loading={initLoading}
              itemLayout="horizontal"
              // loadMore={loadMore}
              dataSource={list}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <a onClick={deleteQ(item.id)} key="list-loadmore-edit">
                      <DeleteOutlined />
                    </a>,
                    <a onClick={star(item.id, true)} key="list-loadmore-more">
                      <LikeOutlined />
                    </a>,
                  ]}
                >
                  <Skeleton title={false} loading={item.loading} active>
                    <List.Item.Meta
                      title={<Link to={`/post/${item.id}`}>{item.title}</Link>}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Col>
          <Col md={12} sm={24}>
            <List
              header="Starred routes"
              loading={initLoading}
              itemLayout="horizontal"
              // loadMore={loadMore}
              dataSource={starred}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <a onClick={deleteQ(item.id)} key="list-loadmore-edit">
                      <DeleteOutlined />
                    </a>,
                    <a onClick={star(item.id, false)} key="list-loadmore-more">
                      <DislikeOutlined />
                    </a>,
                  ]}
                >
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      title={<Link to={`/post/${item.id}`}>{item.title}</Link>}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Container>
    </SinglePageWrapper>
  );
};

export default UserRoutes;
